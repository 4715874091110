import React, { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents, createEvent, updateEvent } from '../../../../redux/slices/eventsSlice';
import { Container, Row, Col, Dropdown, Button, Alert } from 'react-bootstrap';
import SearchBox from 'components/common/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import EventFileEvent from './event_fileEvent';
import { RootState } from '../../../../redux/store';
import { Event } from '../../../../redux/slices/eventsSlice';

const ListEvents = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Get data and pagination from the Redux state
    const events = useSelector((state: RootState) => state.events.items);
    const pagination = useSelector((state: RootState) => ({
        currentPage: state.events.currentPage,
        totalPages: state.events.totalPages,
        totalItems: state.events.totalItems,
        pageSize: state.events.pageSize,
    }));
    const loading = useSelector((state: RootState) => state.events.status === 'loading');
    const error = useSelector((state: RootState) => state.events.error);

    const { currentPage, totalPages, totalItems, pageSize } = pagination;

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    useEffect(() => {
        dispatch(
            fetchEvents({
                page: currentPage,
                limit: pageSize,
                search: searchTerm.length > 3 ? searchTerm : undefined,
            })
        );
    }, [dispatch, currentPage, pageSize, searchTerm]);

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                setSearchTerm(value.length > 3 ? value : '');
            }, 300)
        );
    };

    const handlePageChange = (page: number) => {
        dispatch(
            fetchEvents({
                page,
                limit: pageSize,
                search: searchTerm,
            })
        );
    };

    const handlePageSizeChange = (limit: number) => {
        dispatch(
            fetchEvents({
                page: 1, // Reset to the first page
                limit,
                search: searchTerm,
            })
        );
    };

    const handleAction = (action: string, rowData: Event) => {
        if (action === 'view') {
            console.log(`View Event: ${rowData.event_id}`);
            // Add your navigation logic here
        } else if (action === 'edit') {
            setSelectedEvent(rowData);
            setEditModalVisible(true);
        }
    };

    const handleSave = async (eventData: Event): Promise<any> => {
        try {
            let resultAction;
            if (eventData.event_id) {
                // Existing event: Call update action
                resultAction = await dispatch(updateEvent(eventData));
            } else {
                // New event: Call create action
                resultAction = await dispatch(createEvent(eventData));
            }
    
            if (updateEvent.fulfilled.match(resultAction) || createEvent.fulfilled.match(resultAction)) {
                console.log('Save successful:', resultAction.payload);
                setEditModalVisible(false); // Close the modal on success
                return { success: true };
            }
    
            // Handle errors explicitly
            if (updateEvent.rejected.match(resultAction) || createEvent.rejected.match(resultAction)) {
                const errorMessage = resultAction.error.message || 'Failed to save event.';
                console.error('Save failed:', errorMessage);
                return Promise.reject(new Error(errorMessage));
            }
    
            throw new Error('Unexpected save result.');
        } catch (error: any) {
            console.error('Failed to save event:', error.message || error);
            return Promise.reject(error); // Pass error details back to the child component
        }
    };
    






    const columns: ColumnDef<Event>[] = [
        {
            accessorKey: 'event_id',
            header: 'ID',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'description',
            header: 'Description',
        },
        {
            accessorKey: 'type',
            header: 'Type',
        },
        {
            accessorKey: 'action.type',
            header: 'Action Type',
        },
        {
            accessorKey: 'config.directory',
            header: 'Directory',
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                const rowData = row.original;
                const actions = [
                    { label: 'View', action: 'view' },
                    { label: 'Edit', action: 'edit' },
                ];

                return (
                    <Dropdown>
                        <Dropdown.Toggle variant="phoenix-secondary" size="sm">
                            <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {actions.map((actionItem, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => handleAction(actionItem.action, rowData)}
                                >
                                    {actionItem.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        },
    ];

    const table = useAdvanceTable({
        data: events,
        columns,
        pageSize,
        pagination: true,
        sortable: true,
        selection: false,
    });

    return (
        <Container fluid className="p-0">
            {/* New/Edit Event Modal */}
            <EventFileEvent
                show={editModalVisible}
                onClose={() => setEditModalVisible(false)}
                event={selectedEvent}
                onSave={handleSave}
            />


            <Row className="m-0">
                <Col className="p-0">
                    {error && <Alert variant="danger">{t('error-generic')}</Alert>}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <Button variant="primary" onClick={() => {
                            setSelectedEvent(null); // Clear selected event for new
                            setEditModalVisible(true);
                        }}>
                            New Event
                        </Button>
                        <div className="d-flex gap-2">
                            <SearchBox
                                placeholder={t('search') + '...'}
                                size="sm"
                                onChange={handleSearchInputChange}
                            />
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-secondary" id="dropdown-limit">
                                    {t('limit')}: {pageSize}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {[10, 25, 50, 100].map((limit) => (
                                        <Dropdown.Item key={limit} onClick={() => handlePageSizeChange(limit)}>
                                            {limit}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <AdvanceTableProvider {...table}>
                        <AdvanceTable
                            tableProps={{
                                size: 'sm',
                                hover: true,
                                className: 'phoenix-table fs-9 mb-0 border-top border-translucent',
                            }}
                            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
                        />
                        <AdvanceTableFooter
                            navBtn
                            pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalItems={totalItems}
                            pageSize={pageSize}
                            onPageChange={(page) => handlePageChange(page)}
                        />
                    </AdvanceTableProvider>
                </Col>
            </Row>
        </Container>
    );
};

export default ListEvents;
