import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';

const AsideComponent = ({ onDragStart, parameters, setParameters }) => {
  const { t } = useTranslation();
  const [showParameters, setShowParameters] = useState(true);
  const [showTaskLibrary, setShowTaskLibrary] = useState(true);
  const [showDataFormatting, setShowDataFormatting] = useState(true);

  const toggleParameters = () => setShowParameters(!showParameters);
  const toggleTaskLibrary = () => setShowTaskLibrary(!showTaskLibrary);
  const toggleDataFormatting = () => setShowDataFormatting(!showDataFormatting);

  const handleParameterChange = (index, field, value) => {
    const updatedParameters = parameters.map((param, idx) =>
      idx === index ? { ...param, [field]: value } : param
    );
    setParameters(updatedParameters);
  };

  const addNewParameter = () => {
    const newParameter = {
      flow_parameter_id: Math.random().toString(36).substr(2, 9), // Temporary unique ID
      name: '',
      type: '',
      default_value: '',
    };
    setParameters([...parameters, newParameter]);
  };

  const removeParameter = (index) => {
    const updatedParameters = parameters.filter((_, idx) => idx !== index);
    setParameters(updatedParameters);
  };

  return (
    <aside>
      <div className="collapsible-section">
        <div className="section-header" onClick={toggleParameters} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
          Parameters {showParameters ? '▲' : '▼'}
        </div>
        {showParameters && (
          <div className="section-content">
            <Button variant="link" onClick={addNewParameter} style={{ padding: 0 }}>
              <FaPlus /> {t('add-parameter')}
            </Button>
            {parameters.length > 0 ? (
              parameters.map((param, index) => (
                <div key={param.flow_parameter_id} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: 1 }}>
                    <Form.Group controlId={`paramName${index}`}>
                      <Form.Label>{t('name')}</Form.Label>
                      <Form.Control
                        type="text"
                        value={param.name}
                        onChange={(e) => handleParameterChange(index, 'name', e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId={`paramDefaultValue${index}`}>
                      <Form.Label>{t('default-value')}</Form.Label>
                      <Form.Control
                        type="text"
                        value={param.default_value}
                        onChange={(e) => handleParameterChange(index, 'default_value', e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <Button variant="link" onClick={() => removeParameter(index)} style={{ padding: 0 }}>
                    <FaTimes />
                  </Button>
                </div>
              ))
            ) : (
              <div>{t('no-parameters-found')}.</div>
            )}
          </div>
        )}
      </div>
      <div className="collapsible-section">
        <div className="section-header" onClick={toggleTaskLibrary} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
          Node {t('library')} {showTaskLibrary ? '▲' : '▼'}
        </div>
        {showTaskLibrary && (
          <div className="section-content">
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'sql')} draggable>
              SQL Query
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'rest')} draggable>
              REST Web Service
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'sap_abap_run')} draggable>
              SAP Job
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'bash')} draggable>
              BASH Script
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'bash')} draggable>
              CMD Script
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'bash')} draggable>
              Powershell Script
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'sendMail')} draggable>
              Send Mail
            </div>
            <div className="dndnode custom" onDragStart={(event) => onDragStart(event, 'workflowCreate')} draggable>
              Workflow - Create
            </div>
            <div className="dndnode custom" onDragStart={(event) => onDragStart(event, 'workflowActionCreate')} draggable>
              Workflow - Add Action
            </div>
            
          </div>
        )}
      </div>
      <div className="collapsible-section">
        <div className="section-header" onClick={toggleDataFormatting} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
          Data Formatting {showDataFormatting ? '▲' : '▼'}
        </div>
        {showDataFormatting && (
          <div className="section-content">
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'tableRead')} draggable>
              Read Table
            </div>
            <div className="dndnode transform" onDragStart={(event) => onDragStart(event, 'transform')} draggable>
              XML to JSON
            </div>
            <div className="dndnode transform" onDragStart={(event) => onDragStart(event, 'transform')} draggable>
              CSV to JSON
            </div>
            <div className="dndnode transform" onDragStart={(event) => onDragStart(event, 'transform')} draggable>
              Excel to JSON
            </div>
            <div className="dndnode transform" onDragStart={(event) => onDragStart(event, 'transform')} draggable>
              JSON to XML
            </div>
            <div className="dndnode transform" onDragStart={(event) => onDragStart(event, 'transform')} draggable>
              JSON to CSV
            </div>
            <div className="dndnode transform" onDragStart={(event) => onDragStart(event, 'transform')} draggable>
              JSON to Excel
            </div>
          </div>
        )}
      </div>
    </aside>
  );
};

export default AsideComponent;
