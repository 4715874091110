import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Pagination, Row } from 'react-bootstrap';
import Button from './Button';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import usePagination from 'hooks/usePagination';
import {
  faAngleRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

interface AdvanceTableFooterProps {
  className?: string;
  pagination?: boolean;
  navBtn?: boolean;
  showViewAllBtn?: boolean;
  currentPage?: number; // External current page (1-based)
  totalPages?: number; // External total pages
  totalItems?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void; // External page change handler
}

const AdvanceTableFooter = ({
  className,
  pagination,
  navBtn,
  showViewAllBtn = true,
  currentPage,
  totalPages,
  totalItems,
  pageSize,
  onPageChange,
}: AdvanceTableFooterProps) => {
  const {
    // setPageSize,
    getPaginationRowModel,
    getPrePaginationRowModel,
    getState,
  } = useAdvanceTableContext();

  // const {
  //   // pagination: { pageSize },
  // } = getState();

  // Derive effective current page and total pages
  const effectivePageIndex = currentPage !== undefined ? currentPage - 1 : 0;
  const effectiveTotalPages = totalPages !== undefined ? totalPages : 1;

  const handlePageChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page + 1); // Convert to 1-based index
    }
  };

  const { t } = useTranslation();
  const rows = getPaginationRowModel().rows;
  const totalRows = getPrePaginationRowModel().rows.length;

  return (
    <Row className={classNames(className, 'align-items-center py-1')}>
      <Col className="d-flex fs-9">
        <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
          {pageSize * effectivePageIndex + 1} to{' '}
          {Math.min(pageSize * (effectivePageIndex + 1), totalItems)}{' '}
          <span className="text-600">items of</span> {totalItems}
        </p>
      </Col>
      {pagination && (
        <Col xs="auto">
          <Pagination className="mb-0 justify-content-center">
            <Pagination.Prev
              disabled={effectivePageIndex === 0}
              onClick={() => handlePageChange(effectivePageIndex - 1)}
            >
              {t('previous')}
            </Pagination.Prev>
            {Array.from({ length: effectiveTotalPages }, (_, i) => i + 1).map((page) => (
              <Pagination.Item
                key={page}
                active={effectivePageIndex + 1 === page}
                onClick={() => handlePageChange(page - 1)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={effectivePageIndex + 1 === effectiveTotalPages}
              onClick={() => handlePageChange(effectivePageIndex + 1)}
            >
              {t('next')}
            </Pagination.Next>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};


export default AdvanceTableFooter;
