import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// Event type definitions
interface EventAction {
    type: string; // e.g., 'startFlow'
    flow_id: number | null; // Update to match backend expectation
    parameters: { name: string; value: any }[];
}



export interface EventConfig {
    worker: string;
    directory: string;
    namingpattern: string;
    move: boolean;
    movedirectory?: string; // Optional if `move` is true
}

export interface Event {
    event_id: number;
    name: string;
    description?: string; // Optional if not all events require it
    type: string; // e.g., 'fileEvent'
    action: EventAction;
    config: EventConfig;
}


interface EventState {
    items: Event[];
    status: string | null;
    currentPage: number;
    totalPages: number;
    totalItems: number;
    pageSize: number;
    error: string | null; // Add the error property here
}

const initialState: EventState = {
    items: [],
    status: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    pageSize: 10,
    error: null, // Initialize error as null
};


// Fetch all events
export const fetchEvents = createAsyncThunk(
    'events/fetchEvents',
    async ({ page = 1, limit = 10, search = '' }: { page?: number; limit?: number; search?: string }) => {
        const response = await api.get('/events', {
            params: { page, limit, search },
        });
        return response.data;
    }
);


// Fetch a specific event by ID
export const fetchEventById = createAsyncThunk('events/fetchEventById', async (event_id: number) => {
    const response = await api.get(`/events/${event_id}`);
    return response.data; // Ensure the API returns the full event, including `description`
});


// Create a new event
export const createEvent = createAsyncThunk('events/createEvent', async (event: Omit<Event, 'event_id'>) => {
    const response = await api.post('/events', event); // Ensure `description` is part of the `event` object
    return response.data;
});


// Update an existing event
export const updateEvent = createAsyncThunk('events/updateEvent', async (event: Event) => {
    const response = await api.put(`/events/${event.event_id}`, event); // Ensure `description` is included
    return response.data;
});

// Delete an event by ID
export const deleteEvent = createAsyncThunk('events/deleteEvent', async (event_id: number) => {
    await api.delete(`/events/${event_id}`);
    return event_id;
});

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all events
            .addCase(fetchEvents.pending, (state) => {
                state.status = 'loading';
                state.error = null; // Reset error on new fetch
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                const { data, currentPage, totalPages, totalItems, pageSize } = action.payload;
                state.items = data; // `description` should already be part of `data`
                state.currentPage = currentPage;
                state.totalPages = totalPages;
                state.totalItems = totalItems;
                state.pageSize = pageSize;
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Something went wrong'; // Capture error message
            })

            // Fetch a specific event by ID
            .addCase(fetchEventById.fulfilled, (state, action) => {
                const existingEventIndex = state.items.findIndex(
                    (event) => event.event_id === action.payload.event_id
                );
                if (existingEventIndex !== -1) {
                    state.items[existingEventIndex] = action.payload;
                } else {
                    state.items.push(action.payload);
                }
            })

            // Create a new event
.addCase(createEvent.fulfilled, (state, action) => {
    state.items.push(action.payload); // Successfully created event
    state.status = 'succeeded';
    state.error = null; // Clear any previous error
})
.addCase(createEvent.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message || 'Failed to create event'; // Capture error
})

.addCase(updateEvent.fulfilled, (state, action) => {
    const index = state.items.findIndex(
        (event) => event.event_id === action.payload.event_id
    );
    if (index !== -1) {
        state.items[index] = action.payload; // Successfully updated event
    }
    state.status = 'succeeded';
    state.error = null; // Clear any previous error
})
.addCase(updateEvent.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message || 'Failed to update event'; // Capture error
})


            // Delete an event by ID
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.items = state.items.filter((event) => event.event_id !== action.payload);
            });
    },
});

export default eventsSlice.reducer;
