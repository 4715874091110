import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

interface Header {
    id: string;
    key: string;
    value: string;
}

interface ConnectionRestProps {
    connectionData: any;
    handleParameterChange: (key: string, value: any) => void;
}

const ConnectionRest: React.FC<ConnectionRestProps> = ({ connectionData, handleParameterChange }) => {
    const { t } = useTranslation();
    const authType = connectionData.connection_parameters.auth_type || 'None';
    const headers = connectionData.connection_parameters.headers || [];

    const handleAddHeader = () => {
        const newHeader = { id: uuidv4(), key: '', value: '' };
        handleParameterChange('headers', [...headers, newHeader]);
    };

    const handleHeaderChange = (index: number, key: string, value: string) => {
        const updatedHeaders = headers.map((header: Header, i: number) => 
            i === index ? { ...header, [key]: value } : header
        );
        handleParameterChange('headers', updatedHeaders);
    };

    const handleRemoveHeader = (index: number) => {
        const updatedHeaders = headers.filter((_: Header, i: number) => i !== index);
        handleParameterChange('headers', updatedHeaders);
    };

    return (
        <>
            <Form.Group controlId="baseUrl">
                <Form.Label>{t('base-url')}</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.base_url || ''}
                    onChange={(e) => handleParameterChange('base_url', e.target.value)}
                />
            </Form.Group>
            
            <Form.Group controlId="authMethod">
                <Form.Label>{t('authentication-method')}</Form.Label>
                <Form.Control
                    as="select"
                    value={authType}
                    onChange={(e) => handleParameterChange('auth_type', e.target.value)}
                >
                    <option value="None">None</option>
                    <option value="Basic">Basic</option>
                    <option value="Bearer">Bearer</option>
                </Form.Control>
            </Form.Group>

            {authType === 'Bearer' && (
                <Form.Group controlId="token">
                    <Form.Label>Token</Form.Label>
                    <Form.Control
                        type="text"
                        value={connectionData.connection_parameters.token || ''}
                        onChange={(e) => handleParameterChange('token', e.target.value)}
                    />
                </Form.Group>
            )}

            {authType === 'Basic' && (
                <>
                    <Form.Group controlId="username">
                        <Form.Label>{t('username')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={connectionData.connection_parameters.username || ''}
                            onChange={(e) => handleParameterChange('username', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>{t('login.password')}</Form.Label>
                        <Form.Control
                            type="password"
                            value={connectionData.connection_parameters.password || ''}
                            onChange={(e) => handleParameterChange('password', e.target.value)}
                        />
                    </Form.Group>
                </>
            )}

            <Form.Group controlId="timeout">
                <Form.Label>Timeout</Form.Label>
                <Form.Control
                    type="number"
                    value={connectionData.connection_parameters.timeout || ''}
                    onChange={(e) => handleParameterChange('timeout', parseInt(e.target.value, 10) || 0)}
                />
            </Form.Group>

            <Form.Group controlId="headers">
                <Form.Label>Headers</Form.Label>
                {headers.map((header: Header, index: number) => (
                    <Row key={header.id} className="mb-2">
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Key"
                                value={header.key}
                                onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Value"
                                value={header.value}
                                onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button variant="danger" onClick={() => handleRemoveHeader(index)}>
                                -
                            </Button>
                        </Col>
                    </Row>
                ))}
                <br /><Button variant="primary" onClick={handleAddHeader}>
                    + {t('add-header')}
                </Button>
            </Form.Group>
        </>
    );
};

export default ConnectionRest;
