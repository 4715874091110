import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { JSONPath } from 'jsonpath-plus';
import api from 'services/api';
import Editor from 'react-simple-code-editor'; // Code editor for longText
import Prism from 'prismjs'; // Syntax highlighting
import 'prismjs/components/prism-bash'; // Example language support
import 'prismjs/themes/prism-tomorrow.css'; // Styling

interface NodeTypeField {
  name: string;
  type: string;
  Value?: any;
  Default?: any;
  editable?: boolean;
  JSONPath?: string;
  ValuesApi?: string;
}

interface NodeTypeFieldProps {
  field: NodeTypeField;
  onFieldChange: (fieldName: string, value: any) => void;
}

const NodeTypeFieldDisplay: React.FC<NodeTypeFieldProps> = ({ field, onFieldChange }) => {
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string>(field.Value || '');

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      if (field.ValuesApi) {
        try {
          const response = await api.get(field.ValuesApi);
          const data = response.data;

          if (field.JSONPath) {
            const filteredOptions = JSONPath({ path: field.JSONPath, json: data });
            setDropdownOptions(filteredOptions);
          } else {
            setDropdownOptions(Array.isArray(data) ? data : []);
          }
        } catch (err) {
          console.error(`Failed to fetch dropdown options for ${field.name}:`, err);
          setError('Failed to load dropdown options');
        }
      }
    };

    if (field.type === 'Dropdown') {
      fetchDropdownOptions();
    }
  }, [field.ValuesApi, field.JSONPath, field.type]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const target = e.target;
    const value =
      target instanceof HTMLSelectElement || target instanceof HTMLInputElement
        ? target.value
        : target instanceof HTMLTextAreaElement
        ? target.value
        : null;

    onFieldChange(field.name, value);
  };

  const handleModalSave = () => {
    onFieldChange(field.name, modalContent);
    setShowModal(false);
  };

  const renderField = () => {
    switch (field.type) {
      case 'Dropdown':
        return (
          <Form.Group controlId={`form${field.name}`}>
            <Form.Label>{field.name}</Form.Label>
            <Form.Control
              as="select"
              value={field.Value ?? ''} // Default to empty string if undefined
              onChange={handleChange}
            >
              <option value="">Select an option</option>
              {dropdownOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </Form.Group>
        );
      case 'Boolean':
        return (
          <Form.Check
            type="checkbox"
            label={field.name}
            checked={field.Value ?? field.Default ?? false}
            onChange={(e) => onFieldChange(field.name, e.target.checked)}
          />
        );
      case 'longText':
        return (
          <>
            <Form.Group controlId={`form${field.name}`}>
              <Form.Label>{field.name}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={field.Value ?? ''}
                onClick={() => setShowModal(true)}
                readOnly
              />
            </Form.Group>

            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Edit {field.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Editor
                  value={modalContent}
                  onValueChange={(code) => setModalContent(code)}
                  highlight={(code) => Prism.highlight(code, Prism.languages.bash, 'bash')}
                  padding={10}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    minHeight: '300px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '5px',
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleModalSave}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      default:
        return (
          <Form.Group controlId={`form${field.name}`}>
            <Form.Label>{field.name}</Form.Label>
            <Form.Control
              type="text"
              value={field.Value ?? ''}
              onChange={handleChange}
            />
          </Form.Group>
        );
    }
  };

  return <div className="mb-3">{renderField()}</div>;
};

export default NodeTypeFieldDisplay;
