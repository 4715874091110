import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerNodeDefinitions, updateCustomerNodeDefinition } from '../../../../redux/slices/customer_node_definitionsSlice';
import { Container, Row, Col, Dropdown, Alert } from 'react-bootstrap';
import SearchBox from 'components/common/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import EditCustomerNodeDefinition from './EditCustomerNodeDefinition';


interface CustomerNodeDefinition {
  customer_node_definition_id: number;
  master_node_type: string;
  name: string;
  created_at: string;
}

const ListCustomerNodeDefinitions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Get data and pagination from the Redux state
  const customerNodeDefinitions = useSelector((state: any) => state.customerNodeDefinitions.items);
  const pagination = useSelector((state: any) => state.customerNodeDefinitions.pagination);
  const loading = useSelector((state: any) => state.customerNodeDefinitions.loading);
  const error = useSelector((state: any) => state.customerNodeDefinitions.error);

  const { currentPage, totalPages, totalItems, pageSize } = pagination;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);


  useEffect(() => {
    dispatch(
      fetchCustomerNodeDefinitions({
        page: currentPage,
        limit: pageSize,
        search: searchTerm.length > 3 ? searchTerm : undefined,
        sortBy: 'customer_node_definition_id',
        sortOrder: 'asc',
      })
    );
  }, [dispatch, currentPage, pageSize, searchTerm]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setSearchTerm(value.length > 3 ? value : '');
      }, 300)
    );
  };

  const handlePageChange = (page: number) => {
    dispatch(
      fetchCustomerNodeDefinitions({
        page,
        limit: pageSize,
        search: searchTerm,
        sortBy: 'customer_node_definition_id',
        sortOrder: 'asc',
      })
    );
  };

  const handlePageSizeChange = (limit: number) => {
    dispatch(
      fetchCustomerNodeDefinitions({
        page: 1, // Reset to the first page
        limit,
        search: searchTerm,
        sortBy: 'customer_node_definition_id',
        sortOrder: 'asc',
      })
    );
  };

  const handleAction = (action: string, rowData: CustomerNodeDefinition) => {
    if (action === 'edit') {
      setSelectedNodeId(rowData.customer_node_definition_id);
      setEditModalVisible(true);
    }
  };

  const handleSave = (updatedData: CustomerNodeDefinition) => {
    const dispatch = useDispatch();
  
    console.log('Saving Updated Data:', updatedData);
  
    
  };


  const columns: ColumnDef<CustomerNodeDefinition>[] = [
    {
      accessorKey: 'customer_node_definition_id',
      header: 'ID',
    },
    {
      accessorKey: 'master_node_type',
      header: 'Master Node Type',
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'created_at',
      header: t('created-at'),
      cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString(),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const rowData = row.original;
        const actions = [
          { label: 'View', action: 'view' },
          { label: 'Edit', action: 'edit' }, // Added Edit option
          { label: 'Delete', action: 'delete' },
        ];

        return (
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-secondary" size="sm">
              <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((actionItem, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleAction(actionItem.action, rowData)}
                >
                  {actionItem.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data: customerNodeDefinitions,
    columns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: false,
  });

  return (


    <Container fluid className="p-0">

      <EditCustomerNodeDefinition
        show={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        selectedNodeId={selectedNodeId}
        onSave={handleSave}
      />;

      <Row className="m-0">
        <Col className="p-0">
          {error && <Alert variant="danger">{t('error-generic')}</Alert>}
          <div className="d-flex gap-2 mb-3">
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              onChange={handleSearchInputChange}
              className="mx-auto"
            />
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-limit">
                {t('limit')}: {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[10, 25, 50, 100].map((limit) => (
                  <Dropdown.Item key={limit} onClick={() => handlePageSizeChange(limit)}>
                    {limit}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                hover: true,
                className: 'phoenix-table fs-9 mb-0 border-top border-translucent',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter
              navBtn
              pagination
              currentPage={pagination.currentPage} // Pass Redux state
              totalPages={pagination.totalPages} // Pass Redux state
              totalItems={pagination.totalItems}
              pageSize={pagination.pageSize}
              onPageChange={(page) => handlePageChange(page)} // Handle page change
            />
          </AdvanceTableProvider>
        </Col>
      </Row>
    </Container>
  );
};

export default ListCustomerNodeDefinitions;
