import React from 'react';
import { Form } from 'react-bootstrap';

interface ConnectionDatabaseProps {
    connectionData: any;
    handleParameterChange: (key: string, value: any) => void;
}

const ConnectionDatabase: React.FC<ConnectionDatabaseProps> = ({ connectionData, handleParameterChange }) => {
    const databaseType = connectionData.connection_parameters.database_type || '';

    return (
        <>
            <Form.Group controlId="databaseType">
                <Form.Label>Database Type</Form.Label>
                <Form.Control
                    as="select"
                    value={databaseType}
                    onChange={(e) => handleParameterChange('database_type', e.target.value)}
                >
                    <option value="">Select Database Type</option>
                    <option value="PostgreSQL">PostgreSQL</option>
                    <option value="Microsoft SQL Server">Microsoft SQL Server</option>
                    <option value="Oracle">Oracle</option>
                </Form.Control>
            </Form.Group>
            
            <Form.Group controlId="host">
                <Form.Label>Host</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.host || ''}
                    onChange={(e) => handleParameterChange('host', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="port">
                <Form.Label>Port</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.port || ''}
                    onChange={(e) => handleParameterChange('port', parseInt(e.target.value, 10) || 0)}
                />
            </Form.Group>

            <Form.Group controlId="database">
                <Form.Label>Database</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.database || ''}
                    onChange={(e) => handleParameterChange('database', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.username || ''}
                    onChange={(e) => handleParameterChange('username', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    value={connectionData.connection_parameters.password || ''}
                    onChange={(e) => handleParameterChange('password', e.target.value)}
                />
            </Form.Group>

            {/* Conditional rendering for Oracle-specific property */}
            {databaseType === 'Oracle' && (
                <Form.Group controlId="privilege">
                    <Form.Label>Privilege</Form.Label>
                    <Form.Control
                        type="text"
                        value={connectionData.connection_parameters.privilege || ''}
                        onChange={(e) => handleParameterChange('privilege', e.target.value)}
                    />
                </Form.Group>
            )}
        </>
    );
};

export default ConnectionDatabase;
