import App from 'App';
import AuthCallback from 'components/modules/auth/callback';
import MainLayout from 'layouts/MainLayout';
import ListBlocks from 'pages/apps/platform/blocks/listBlocks';
import PopupEditBlock from 'pages/apps/platform/blocks/popupEditBlock';
import ListConfig from 'pages/apps/platform/config/listConfig';
import ListConnections from 'pages/apps/platform/connections/listConnections';
import Documentation from 'pages/apps/platform/documentation/Documentation';
import FlowInstanceViewer from 'pages/apps/platform/flows/flows_instances/flowInstanceViewer';
import ListFlowInstances from 'pages/apps/platform/flows/flows_instances/listFlowInstances';
import ListFlows from 'pages/apps/platform/flows/listFlows';
import NcpDescription from 'pages/apps/platform/ncp-description';
import ListRoles from 'pages/apps/platform/roles/listRoles';
import Datasources from 'pages/apps/platform/scheduler/Datasources';
import FlowEditor from 'pages/apps/platform/scheduler/FlowEditor';
import PopupFlowEditor from 'pages/apps/platform/scheduler/popupFlowEditor';
import TaskDefinitions from 'pages/apps/platform/scheduler/TaskDefinitions';
import TaskInstances from 'pages/apps/platform/scheduler/TaskInstances';
import WorkflowDefinitions from 'pages/apps/platform/scheduler/WorkflowDefinitions';
import WorkflowInstances from 'pages/apps/platform/scheduler/WorkflowInstances';
import WorkflowTiming from 'pages/apps/platform/scheduler/WorkflowTiming';
import ListTables from 'pages/apps/platform/tables/listTables';
import ListUsers from 'pages/apps/platform/users/listUsers';
import ListWorkers from 'pages/apps/platform/workers/listWorkers';
import Error403 from 'pages/error/Error403';
import Error404 from 'pages/error/Error404';
import Error500 from 'pages/error/Error500';
import CardForgotPassword from 'pages/pages/authentication/card/ForgotPassword';
import CardLockScreen from 'pages/pages/authentication/card/LockScreen';
import CardResetPassword from 'pages/pages/authentication/card/ResetPassword';
import CardSignIn from 'pages/pages/authentication/card/SignIn';
import CardSignOut from 'pages/pages/authentication/card/SignOut';
import CardSignUp from 'pages/pages/authentication/card/SignUp';
import CardTwoFA from 'pages/pages/authentication/card/TwoFA';
import SimpleForgotPassword from 'pages/pages/authentication/simple/ForgotPassword';
import SimpleLockScreen from 'pages/pages/authentication/simple/LockScreen';
import SimpleResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import SimpleSignOut from 'pages/pages/authentication/simple/SignOut';
import SimpleSignUp from 'pages/pages/authentication/simple/SignUp';
import SimpleTwoFA from 'pages/pages/authentication/simple/TwoFA';
import SplitForgotPassword from 'pages/pages/authentication/split/ForgotPassword';
import SplitLockScreen from 'pages/pages/authentication/split/LockScreen';
import SplitResetPassword from 'pages/pages/authentication/split/ResetPassword';
import SplitSignIn from 'pages/pages/authentication/split/SignIn';
import SplitSignOut from 'pages/pages/authentication/split/SignOut';
import SplitSignUp from 'pages/pages/authentication/split/SignUp';
import SplitTwoFA from 'pages/pages/authentication/split/TwoFA';
import Alternate from 'pages/pages/landing/Alternate';
import Default from 'pages/pages/landing/Default';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import { lazy } from 'react';
import { RouteObject, createBrowserRouter } from 'react-router-dom';


import WorkflowDetailedView from 'pages/apps/platform/workflows/WorkflowDetailedView';
import ListAuditTrail from 'pages/apps/platform/audit/ListAuditTrail';
import ListCustomerNodeDefinitions from 'pages/apps/platform/customer_node_definitions/listCustomerNodeDefinitions';
import ListEvents from 'pages/apps/platform/events/listEvents';






const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          // <Provider store={store}>
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
          // </Provider>
        ),
        children: [
          {
            index: true,
            element: <MainLayoutProvider>
            <ListFlowInstances />
          </MainLayoutProvider>
            // <NCDashboard />
          },
          {
            path: '/ems-description',
            element: (
              <MainLayoutProvider>
            <NcpDescription />
          </MainLayoutProvider>
            )
          },
          {
            path: '/documentation',
            element: (
              <MainLayoutProvider>
            <Documentation />
          </MainLayoutProvider>
            )
          },
          {
            path: 'inbox/:workflowId?',
            element: (
              <MainLayoutProvider>
                <WorkflowDetailedView />
              </MainLayoutProvider>
            )
          },
          {
            path: '/audittrail',
            element: <ListAuditTrail />
          },
          {
            path: '/configuration/',
            children: [
              {
                path: 'users',
                element: <ListUsers />
              },
              {
                path: 'roles',
                element: <ListRoles />
              },
              {
                path: 'configitems',
                element: <ListConfig />
              },
              {
                path: 'tables',
                element: <ListTables />
              },
              {
                path: 'connections',
                element: <ListConnections />
              },
              {
                path: 'workers',
                element: <ListWorkers />
              }
            ]
          },
          {
            path: '/automation/',
            children: [
              {
                path: 'node_definitions',
                element: <ListCustomerNodeDefinitions />
              },
              {
                path: 'events',
                element: <ListEvents />
              }
            ]
          },
          {
            path: '/apps',
            children: [
              {
                path: 'ncp',
                children: [
                  {
                    path: 'blocks',
                    element: <ListBlocks />
                  },
                  // {
                  //   path: 'connection',
                  //   element: <Ems_contract />
                  // },
                ]
              },
              {
                path: 'scheduler',
                children: [
                  {
                    path: 'workflowdefinitions',
                    element: <WorkflowDefinitions />
                  },
                  {
                    path: 'workflowinstances',
                    element: <WorkflowInstances />
                  },
                  {
                    path: 'workflowtiming',
                    element: <WorkflowTiming />
                  },
                  {
                    path: 'taskdefinitions',
                    element: <TaskDefinitions />
                  },
                  {
                    path: 'taskinstances',
                    element: <TaskInstances />
                  },
                  {
                    path: 'datasources',
                    element: <Datasources />
                  },
                  {
                    path: 'floweditor',
                    element: <FlowEditor />
                  },
                  {
                    path: 'editflow/:itemId',
                    element: <FlowEditor />
                  },
                  {
                    path: 'flows',
                    element: <ListFlows />
                  },
                  {
                    path: 'flowsinstances',
                    element: <ListFlowInstances />
                  },
                  {
                    path: 'flowinstanceviewer/:instanceId',
                    element: <FlowInstanceViewer />
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/login',
        element: <SimpleSignIn />
      },
      {
        path: '/callback',
        element: <AuthCallback />
      },
      {
        path: 'logoff',
        element: <SimpleSignOut />
      },
      {
        path: '/popups/editBlock/:itemId',
        element: <PopupEditBlock />
      },
      {
        path: 'pages/landing',
        children: [
          {
            path: 'default',
            element: <Default />
          },
          {
            path: 'alternate',
            element: <Alternate />
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/pages/authentication/simple/',
        children: [
          {
            path: 'sign-in',
            element: <SimpleSignIn />
          },
          {
            path: 'sign-up',
            element: <SimpleSignUp />
          },
          {
            path: 'sign-out',
            element: <SimpleSignOut />
          },
          {
            path: 'forgot-password',
            element: <SimpleForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SimpleResetPassword />
          },
          {
            path: 'lock-screen',
            element: <SimpleLockScreen />
          },
          {
            path: '2FA',
            element: <SimpleTwoFA />
          }
        ]
      },
      {
        path: '/pages/authentication/split/',
        children: [
          {
            path: 'sign-in',
            element: <SplitSignIn />
          },
          {
            path: 'sign-up',
            element: <SplitSignUp />
          },
          {
            path: 'sign-out',
            element: <SplitSignOut />
          },
          {
            path: 'forgot-password',
            element: <SplitForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SplitResetPassword />
          },
          {
            path: 'lock-screen',
            element: <SplitLockScreen />
          },
          {
            path: '2FA',
            element: <SplitTwoFA />
          }
        ]
      },
      {
        path: '/pages/authentication/card/',
        children: [
          {
            path: 'sign-in',
            element: <CardSignIn />
          },
          {
            path: 'sign-up',
            element: <CardSignUp />
          },
          {
            path: 'sign-out',
            element: <CardSignOut />
          },
          {
            path: 'forgot-password',
            element: <CardForgotPassword />
          },
          {
            path: 'reset-password',
            element: <CardResetPassword />
          },
          {
            path: 'lock-screen',
            element: <CardLockScreen />
          },
          {
            path: '2FA',
            element: <CardTwoFA />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  },
  {
    path: '/popups/editFlow/:itemId',
    element: <PopupFlowEditor />
  },
];



export const router = createBrowserRouter(routes);

export default routes;
